import { useEffect } from "react";
import { HomeReview } from '../home/HomeReview';
import SectionBrandStory from "./SectionBrandStory";
import SectionWhyLearnWithUs from "./SectionWhyLearnWithUs";
import SectionModernMethod from "./SectionModernMethod";
import SectionPartners from "./SectionPartners";
import { HomeBanner } from "./HomeBanner";

const founders = [
    {
        name: 'Nguyễn Thị Hường',
        avatar: 'assets/images/Huongnt.jpg'
    },
    {
        name: 'Nguyễn Thị Phương Thanh',
        avatar: 'assets/images/Thanhntp.jpg'
    },
    {
        name: 'Nguyễn Thế Phong',
        avatar: 'assets/images/Phongnt3.jpg'
    },
    {
        name: 'Phạm Chí Kiên',
        avatar: 'assets/images/Kienpc.png'
    },
    {
        name: 'Trần Dũng',
        avatar: 'assets/images/Tran Dung.jpg'
    }
]

const AboutUs = () => {

    useEffect(() => {
        document.body.classList.add('home');
        return () => {
            document.body.classList.remove('home');
        };
    }, [])

    window.gtag('event', 'page_view', {
        page_location: window.location
    })

    return (
        <div className='w-100'>
            <HomeBanner />
            {/* <section className='d-flex flex-column bg-light-pink' style={{ height: '40vh' }}>
                <section className="container">
                    <section className="row mt-5">
                        <div className="col-md-12 col-lg-8 text-left px-5">
                            <h1 className="text-uppercase mb-1 h1 fw-700">Về chúng tôi</h1>
                            <div className="h3 text-uppercase fw-400" >Trang chủ / Về chúng tôi</div>
                        </div>
                    </section>
                </section>
                <div className='w-100'>
                    <img src='/assets/images/courses-banner-deco.png' className='w-100 d-block' />
                </div>
            </section> */}
            <SectionBrandStory />
            <SectionWhyLearnWithUs />
            {/* <SectionWeHave /> */}
            <SectionModernMethod />
            <HomeReview />
            <SectionPartners />
        </div>
    )
}

export { AboutUs }