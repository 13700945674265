import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as allIcon from "@fortawesome/free-solid-svg-icons";

const SectionWhyLearnWithUs = () => {
  return (<section className="bg-light-pink py-5">
    <div className="container">
      <div className="row text-center">
        <div className="col-12 mt-4 ">
          <p className="text-dark-orange h1 fw-700 text-uppercase">Vì sao nên học cùng chúng tôi</p>
        </div>
        <div className="col-12 mt-4 d-flex d-col justify-content-center">
          <div className="w-25 border-primary"></div>
        </div>
        <div className="col-12 mt-4 row">
          <div className="col-3">
            <div className='m-auto w-50px h-50px border border-orange rounded-lg text-orange bg-white pt-2 px-2'>
              <FontAwesomeIcon icon={allIcon.faAllergies} size='2x' />
            </div>
            <p className="h4 fw-700 mt-3">Nội dung phong phú</p>
            <p className="h6 mt-3">Hơn 400 khoá học với hàng chục lĩnh vực giúp bạn dễ dàng tìm được nội dung phù hợp nhất.</p>
          </div>
          <div className="col-3">
            <div className='m-auto w-50px h-50px border border-orange rounded-lg text-orange bg-white pt-2 px-2'>
              <FontAwesomeIcon icon={allIcon.faAward} size='2x' />
            </div>
            <p className="h4 fw-700 mt-3">Giảng viên chất lượng</p>
            <p className="h6 mt-3">Những bài học chất lượng được dẫn dắt bởi các giảng viên, người truyền cảm hứng đứng đầu Việt Nam.</p>
          </div>
          <div className="col-3">
            <div className='m-auto w-50px h-50px border border-orange rounded-lg text-orange bg-white pt-2 px-2'>
              <FontAwesomeIcon icon={allIcon.faAtlas} size='2x' />
            </div>
            <p className="h4 fw-700 mt-3">Học mọi lúc mọi nơi</p>
            <p className="h6 mt-3">Hệ thống online sẽ hỗ trợ bạn tham gia học ở bất cứ đâu và bất cứ khi nào.</p>
          </div>
          <div className="col-3">
            <div className='m-auto w-50px h-50px border border-orange rounded-lg text-orange bg-white pt-2 px-2'>
              <FontAwesomeIcon icon={allIcon.faUserCheck} size='2x' />
            </div>
            <p className="h4 fw-700 mt-3">37.402+ người đã học</p>
            <p className="h6 mt-3">Kiến thức là một thứ mà bạn sẽ có được bằng cách cho đi - Ivan Sutherland</p>
          </div>
        </div>

      </div>
    </div>
  </section>)
}

export default SectionWhyLearnWithUs