const SectionModernMethod = () => {
  return (
    <section className="py-5">
      <div className="container">
        <div className="col-12 col-lg-12 text-center px-0 mt-5 text-uppercase">
          <div className="h2 fw-600">Trải nghiệm phương pháp <br /> học tập hiện đại</div>
          <div className="row mt-5 position-relative">
            <div className="col-lg-6 rounded-pill py-3 h2 fw-600 bg-light">
              học mọi lúc mọi nơi
            </div>
            <div className="position-absolute w-100 h-100 d-none d-lg-flex align-items-center justify-content-center">
              <img src="/assets/images/earth.png" className="h-90px" alt="earth" />
            </div>
          </div>
          <div className="row flex-row-reverse mt-4 position-relative">
            <div className="col-lg-6 rounded-pill py-3 h2 fw-600 bg-light">
              tính ứng dụng cao
            </div>
            <div className="position-absolute w-100 h-100 d-none d-lg-flex align-items-center justify-content-center">
              <img src="/assets/images/mobile.png" className="h-90px" alt="mobile" />
            </div>
          </div>
          <div className="row mt-4 position-relative">
            <div className="col-lg-6 rounded-pill py-3 h2 fw-600 bg-light">
              tối ưu chi phí
            </div>
            <div className="position-absolute w-100 h-100 d-none d-lg-flex align-items-center justify-content-center">
              <img src="/assets/images/money.png" className="h-90px" alt="money" />
            </div>
          </div>
          <div className="row flex-row-reverse mt-4 position-relative">
            <div className="col-lg-6 rounded-pill py-3 h2 fw-600 bg-light">
              tương tác giảng viên
            </div>
            <div className="position-absolute w-100 h-100 d-none d-lg-flex align-items-center justify-content-center">
              <img src="/assets/images/interact.png" className="h-90px" alt="interact" />
            </div>
          </div>
          <div className="row mt-4 position-relative">
            <div className="col-lg-6 rounded-pill py-3 h2 fw-600 bg-light">
              hỗ trợ 24/7
            </div>
            <div className="position-absolute w-100 h-100 d-none d-lg-flex align-items-center justify-content-center">
              <img src="/assets/images/customer.png" className="h-90px" alt="customer" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionModernMethod