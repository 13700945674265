import { Carousel } from "react-responsive-carousel"
import { ArrayHelpers } from "../helpers"
import { KOCImage } from "../shared/KocImage"

const SectionPartners = () => {
  const partners = [
    { image: '/assets/images/vmcvietnam.png', name: 'VMCVietNam' },
    { image: '/assets/images/hotkid.png', name: 'HotKid' },
    { image: '/assets/images/bke.png', name: 'BKE' },
    { image: '/assets/images/9talk.png', name: '9Talk' },
]

var pages = ArrayHelpers.arrayToChunks(partners, 6)
  return (
    <section className="py-5">
      <div className="container">
      <div className="h2 fw-600 text-center text-uppercase">đối tác của chúng tôi</div>
        <div className="row mt-3">
          <div className="col-12">
            <Carousel showArrows={true} showThumbs={false} showIndicators={true} showStatus={false} autoPlay={false}>
              {pages.map((page, index) =>
                <div className="w-100 d-flex" key={index}>
                  {page.map((item, index2) => (
                    <div className="w-100 px-4 d-flex align-items-center" key={index2}>
                      <div>
                        <KOCImage className='h-100px objectfit-cover' src={item.image} alt={item.name || "Our partner"}/>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionPartners